
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GeneralService } from 'src/services/general.service';
import { timer } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { SweetAlertService } from 'src/services/sweet-alert.service';

declare var modal: any;
declare var menu:any



@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  video: any
  token: any
  puntosL = 0;
  puntosV = 0;
  set = 1;
  puntosLform: any
  puntosVform: any
  setfomr: any
  id: any
  puntosMarL: any
  puntosMarV: any
  setMar: any
partido:any



idLocal:any
idVisita:any
puntosLocal:any
puntosVisitante:any
derrotasLocal:any
derrotasVisita:any

sumaPL:any
sumaPV:any
sumaDL:any
sumaDV:any
winsL:any
winsV:any

localset=0
visitanteset=0
start:any
mostrarSetL:any
mostrarSetV:any
cambio:any
timeLeft: number = 30;
interval:any
minutes: number = 0;

  documentoForm = new FormGroup({
    ID: new FormControl(''),
    LOCAL: new FormControl(''),
    VISITANTE: new FormControl(''),
    PUNTOSL: new FormControl(''),
    PUNTOSV: new FormControl(''),
    SETP: new FormControl(''),
    GANADOR: new FormControl(''),
  })

  localForm= new FormGroup({
    ID: new FormControl(''),
    PUNTOS: new FormControl(''),
    DERROTAS: new FormControl(''),
    VICTORIAS: new FormControl('')

  })

 visitanteForm= new FormGroup({
    ID: new FormControl(''),
    PUNTOS: new FormControl(''),
    DERROTAS: new FormControl(''),
    VICTORIAS: new FormControl(''),

  })

  name = "Angular";
  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;

  videoItems = [
    {
      name: 'Perfumes Originales Ana Casillas',
      src: 'assets/img/perfumes.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/5a7dRvH.png',
      number:0
    }, {
      name: 'CRMAH',
      src: 'assets/img/crmah.mp4',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31728G1-%20Autlan?alt=media&token=7b1ec603-c8e5-4eef-8040-e20bc4dc04f4',
      number:1
    },
    {
      name: 'Materiales Timo Castellón',
      src: 'assets/img/timo.mp4',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31729G1-%20Autlan?alt=media&token=87bf769e-a78a-44d5-8883-a8ec356e0aa8',
      number:2
    },
    {
      name: 'Horfi',
      src: 'assets/img/horfi.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/64IAN9T.png',
      number:3
    }
    ,
    {
      name: 'El Pueblito',
      src: 'assets/img/pueblito.mp4',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31732G1-%20Autlan?alt=media&token=a6e7d57f-6631-4e8c-96d0-ec9961c7b8ca',
      number:4
    }

,{
  name: 'Los carrizos',
  src: 'assets/img/carrizos.mp4',
  type: 'video/mp4',
  Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31733G1-%20Autlan?alt=media&token=b3b10dc9-6f62-4643-9d39-ea73e9214bca',
  number:5
},

    {
      name: 'La muu',
      src: 'assets/img/lamuu.mp4',
      type: 'video/mp4',
      Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31731G1-%20Autlan?alt=media&token=8062a11a-af27-4473-b75c-6e27b3ca9539',
      number:6
    }

, {
  name: 'Hamburguesas "La terminal"',
  src: 'assets/img/terminal.mp4',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/eHfrqp6.jpeg',
  number:7
}, {
  name: 'Corporativo Funerario',
  src: 'assets/img/funerario.mp4',
  type: 'video/mp4',
      Image: 'https://i.imgur.com/BsrcDR7.png',
      number:8
}, {
  name: 'El caldero',
  src: 'assets/img/caldero.mp4',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/JBLeJtk.jpeg',
  number:9
},
{
  name: 'Deportes peña',
  src: 'assets/img/deportes.mp4',
  type: 'video/mp4',
  Image: 'https://i.imgur.com/3n26TVH.png',
  number:10
}
, {
  name: 'Radiology',
  src: 'assets/img/radio.mp4',
  type: 'video/mp4',
  Image: 'https://firebasestorage.googleapis.com/v0/b/autlan-51170.appspot.com/o/Noticias%2FIPR31735G1-%20Autlan?alt=media&token=58996ad7-f3a4-46af-ab95-5fade0c44851',
  number:11
},
    {
      name: 'Traitte',
      src: 'assets/img/traitte.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/T4Vkr4z.png',
      number:12
    },
    {
      name: 'Carretero To Go',
      src: 'assets/img/carretero.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/5Z2VMB4.png',
      number:13
    }
    ,
    {
      name: 'Mundo del Niño',
      src: 'assets/img/mundo.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/kCJSsF8.jpeg',
      number:14
    }
    ,
    {
      name: 'Rancho el aguaje',
      src: 'assets/img/aguaje.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/t9nGhA6.png',
      number:15
    } ,
    {
      name: 'Mind Water',
      src: 'assets/img/mind.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/GwFEF6n.png',
      number:16
    } ,
    {
      name: 'Favoritos',
      src: 'assets/img/favoritos.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/BkG0DM4.png',
      number:17
    } ,
    {
      name: 'Favoritos',
      src: 'assets/img/favoritos2.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/iO0kHxL.png',
      number:18
    }
    ,
    {
      name: 'Rinconcito',
      src: 'assets/img/rinconcito.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/ZFTPQkn.jpeg',
      number:19
    }   ,
    {
      name: 'Cortito',
      src: 'assets/img/cortito.mp4',
      type: 'video/mp4',
      Image: 'https://i.imgur.com/urdfeoH.png',
      number:20
    }


  ];
  activeIndex = 0;
  currentVideo = this.videoItems[this.activeIndex];
  data: any;
  datos: any
  partidoAct:any
  local:any
  visita:any
  constructor(private router: Router, private service: GeneralService, private alertas: SweetAlertService) { }

  ngOnInit(): void {
    this.start=false
this.cambio = false;
    localStorage.getItem('indice')
this.mostrarSetL=localStorage.getItem('setL')
this.mostrarSetV=localStorage.getItem('setV')


    this.puntosMarL = localStorage.getItem('puntosL')
    this.puntosMarV = localStorage.getItem('puntosV')
    this.setMar = localStorage.getItem('Set')

    this.puntosL= this.puntosMarL
    this.puntosV= this.puntosMarV
    this.setMar= this.setMar

    this.currentVideo = this.videoItems[this.activeIndex];
    this.oberserableTimer()

    if (localStorage.getItem('token')) {
      this.token = true;
    } else {
      this.token = false;

    }


    this.service.ultimoPartido().subscribe(res => {
      this.partido = res.body;



      this.service.listaEquipoID(this.partido[0].LOCAL).subscribe(res => {
        this.local = res.body;
        this.idLocal= this.local[0].ID
        this.puntosLocal=this.local[0].PUNTOS
        this.derrotasLocal=this.local[0].DERROTAS
        this.winsL=this.local[0].VICTORIAS

        this.localForm.value.PUNTOS=this.puntosLocal
        this.localForm.value.DERROTAS=this.derrotasLocal
        this.localForm.value.VICTORIAS=this.winsL



      })

      this.service.listaEquipoID(this.partido[0].VISITANTE).subscribe(res => {
        this.visita = res.body;
        this.idVisita=this.visita[0].ID
        this.puntosVisitante=this.visita[0].PUNTOS
        this.derrotasVisita=this.visita[0].DERROTAS
        this.winsV=this.visita[0].VICTORIAS



        this.visitanteForm.value.PUNTOS=this.puntosVisitante
        this.visitanteForm.value.DERROTAS=this.derrotasVisita
        this.visitanteForm.value.VICTORIAS=this.winsV


      })

    })

  }

ejecutarBocina(){

}




listaRepro(number:any){
  this.activeIndex=number;

console.log(this.activeIndex);


  if (this.activeIndex === this.videoItems.length) {
    this.activeIndex = 0;
  }
  this.currentVideo = this.videoItems[this.activeIndex];

}

  startTimerL() {

    this.alertas.alertaDescanso('Tiempo de pausa solicitado',  this.local[0].NOMBRE)

  }

  startP() {

menu()
  }
  startTimerV() {

    this.alertas.alertaDescanso('Tiempo de pausa solicitado',  this.visita[0].NOMBRE)

  }

video1(){

  this.nextVideo()
}
  oberserableTimer() {
    let video: any = document.getElementById("my_video_1");


    const source = timer(100, 200);
    const abc = source.subscribe(val => {




      if (video.currentTime === video.duration) {
        this.nextVideo()



      }


    });
  }


  crear() {

    this.puntosLform = this.puntosL
    this.puntosVform = this.puntosV
    this.setfomr = this.set
    this.documentoForm.value.PUNTOSL = this.puntosLform
    this.documentoForm.value.PUNTOSV = this.puntosVform
    this.documentoForm.value.SETP = this.setfomr
    this.documentoForm.value.ID = this.id
    this.service.nuevaPartido(this.documentoForm).subscribe(res => {
      this.data = res.body;


    })
  }

  nextVideo() {
    this.activeIndex++;


    if (this.activeIndex === this.videoItems.length) {
      this.activeIndex = 0;
    }
    this.currentVideo = this.videoItems[this.activeIndex];


  }

regresarVideo(){
  this.activeIndex--;


  if (this.activeIndex === this.videoItems.length) {
    this.activeIndex = 0;
  }
  this.currentVideo = this.videoItems[this.activeIndex];

}


  initVdo() {
    this.data.play();
  }
  startPlaylistVdo(item: any, index: number) {
    this.activeIndex = index;
    this.currentVideo = item;
    this.initVdo()
  }









  sumarL() {
    this.puntosL++

    this.puntosLform = this.puntosL


    localStorage.setItem('puntosL', this.puntosLform)
this.puntosMarL=localStorage.getItem('puntosL')
  }

  restarL() {
    this.puntosL--

    this.puntosLform = this.puntosL
    localStorage.setItem('puntosL', this.puntosLform)
    this.puntosMarL=localStorage.getItem('puntosL')
  }

  sumarV() {
    this.puntosV++


    this.puntosVform = this.puntosV
    localStorage.setItem('puntosV', this.puntosVform)
    this.puntosMarV=localStorage.getItem('puntosV')

// this.alertas.alertaPunto(this.local[0].NOMBRE)

  }

  restarV() {
    this.puntosV--


    this.puntosVform = this.puntosV



    localStorage.setItem('puntosV', this.puntosVform)
    this.puntosMarV=localStorage.getItem('puntosV')
  }

  sumarS() {
    this.set++


    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')
  }

  restarS() {
    this.set--

    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')
  }


reiniciarM(){

this.alertas.alertaConfirmacion('¿Desea reiniciar el marcador?','').then(res=>{

  if(res.isConfirmed === true){
this.cambio=false
this.mostrarSetV=0
this.mostrarSetL=0
  this.puntosMarL=0
  this.puntosMarV=0
  this.setMar=1

  this.puntosV=0
  this.puntosL=0
  this.set=1



localStorage.setItem('puntosV','0')
localStorage.setItem('puntosL','0')
localStorage.setItem('set','1')


localStorage.setItem('setL','0')
localStorage.setItem('setV','0')

  }})



}

reiniciarMSet(){






    this.puntosMarL=0
    this.puntosMarV=0
    this.setMar=1

    this.puntosV=0
    this.puntosL=0
    this.set=1
    this.mostrarSetV=0
    this.mostrarSetL=0

  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')
  localStorage.setItem('set','1')


  localStorage.setItem('setL','0')
  localStorage.setItem('setV','0')

    }





cambiarSet(){

  this.alertas.alertaConfirmacion('¿Siguiente set?','').then(res=>{

    if(res.isConfirmed === true){



      if (this.mostrarSetL === 1 && this.mostrarSetV === 1) {
        this.cambio=true
      }



if(this.mostrarSetL<2 && this.mostrarSetV<2){
  if(this.puntosMarL>this.puntosMarV){

this.cambio=true
  this.localset++


  this.mostrarSetL=this.localset
  localStorage.setItem('setL', this.mostrarSetL)



  this.set++


  this.setfomr = this.set
  localStorage.setItem('Set', this.setfomr)
  this.setMar=localStorage.getItem('Set')


  this.puntosMarL=0
  this.puntosMarV=0


  this.puntosV=0
  this.puntosL=0


  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')



if(this.mostrarSetL==2){
  this.puntuacion()
  this.terminarSets()
  this.cambio=false
}




  }else if(this.puntosMarL<this.puntosMarV){
    if (this.mostrarSetL == 1 && this.mostrarSetV == 1) {
      this.cambio=false
    }
    this.visitanteset++

    this.mostrarSetV=this.visitanteset
    localStorage.setItem('setV', this.mostrarSetV)
    this.cambio=true



    this.set++


    this.setfomr = this.set
    localStorage.setItem('Set', this.setfomr)
    this.setMar=localStorage.getItem('Set')


    this.puntosMarL=0
    this.puntosMarV=0


    this.puntosV=0
    this.puntosL=0


  localStorage.setItem('puntosV','0')
  localStorage.setItem('puntosL','0')


  if(this.mostrarSetV==2){
    this.terminarSets()
    this.cambio=false
  }
  }




}else{
  this.terminarSets()
  this.cambio=false
}


    }


  })
}




  puntuacion() {


console.log(this.mostrarSetL );

    if (this.mostrarSetL == 2 && this.mostrarSetV == 0) {
      this.sumaPL = this.puntosLocal + 3


      this.sumaDV = this.derrotasVisita + 1

this.sumaDL=this.derrotasLocal
this.sumaPV=this.puntosVisitante

this.localForm.value.ID=this.idLocal
this.localForm.value.DERROTAS=this.sumaDL
this.localForm.value.PUNTOS=this.sumaPL
this.localForm.value.VICTORIAS=this.winsL+1
console.log('gana local 2-0');
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

console.log('gana local 2-0');

        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {


console.log('pierde visita 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }


    if (this.mostrarSetL == 2 && this.mostrarSetV == 1) {
      this.sumaPL = this.puntosLocal + 2
      this.sumaPV = this.puntosVisitante + 1




      this.sumaDV = this.derrotasVisita + 1
      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL+1
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

        this.mostrarSetL = 0
        this.mostrarSetV = 0

console.log('gana local 2-1');
      })


      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {

        console.log('pierde visita 2-1');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }


    if (this.mostrarSetV == 2 && this.mostrarSetL == 0) {
      this.sumaPV = this.puntosVisitante + 3


      this.sumaDL = this.derrotasLocal + 1


      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL
      this.service.editarPuntos(this.localForm.value).subscribe(data => {


        console.log('gana visita 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV+1
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {


        console.log('pierde local 2-0');
        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }

    if (this.mostrarSetV == 2 && this.mostrarSetL == 1) {
      this.sumaPV = this.puntosVisitante + 2
      this.sumaPL = this.puntosLocal + 1
      this.sumaDL = this.derrotasLocal + 1

      this.localForm.value.ID=this.idLocal
      this.localForm.value.DERROTAS=this.sumaDL
      this.localForm.value.PUNTOS=this.sumaPL
      this.localForm.value.VICTORIAS=this.winsL
      this.service.editarPuntos(this.localForm.value).subscribe(data => {

        console.log('pierde local 2-1');

        this.mostrarSetL = 0
        this.mostrarSetV = 0

      })
      this.visitanteForm.value.ID = this.idVisita
      this.visitanteForm.value.DERROTAS=this.sumaDV
      this.visitanteForm.value.PUNTOS=this.sumaPV
      this.visitanteForm.value.VICTORIAS=this.winsV+1
      this.service.editarPuntos(this.visitanteForm.value).subscribe(data => {
        console.log('gana visita 2-1');

        this.mostrarSetL = 0
        this.mostrarSetV = 0
      })

    }


  }




  terminarPA(){





        if(this.puntosMarL>this.puntosMarV){
          this.documentoForm.value.GANADOR= this.local[0].ID
          this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
          this.router.navigate(['/admin/partido'])

        }else if(this.puntosMarV>this.puntosMarL){
          this.documentoForm.value.GANADOR= this.visita[0].ID
          this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
  this.router.navigate(['/admin/partido'])

        }

        this.puntosMarL=0
        this.puntosMarV=0
        this.setMar=1

        this.puntosV=0
        this.puntosL=0
        this.set=1


        localStorage.setItem('puntosV','0')
        localStorage.setItem('puntosL','0')
        localStorage.setItem('set','1')

        this.documentoForm.value.ID=localStorage.getItem('idPartido')
        this.documentoForm.value.LOCAL=this.local[0].ID
        this.documentoForm.value.VISITANTE=this.visita[0].ID
        this.documentoForm.value.PUNTOSL= this.puntosMarL
        this.documentoForm.value.PUNTOSV= this.puntosMarV
        this.documentoForm.value.SETP= this.setMar

        this.service.actualizarPartido(this.documentoForm.value).subscribe(res => {
          this.partidoAct = res.body;
          console.log(this.documentoForm.value);

console.log('entra actua partido');


        })








    }




terminarP(){


  this.alertas.alertaConfirmacion('¿Desea terminar el partido?','').then(res=>{

    if(res.isConfirmed === true){

      if(this.puntosMarL>this.puntosMarV){
        this.documentoForm.value.GANADOR= this.local[0].ID
        this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
        this.router.navigate(['/admin/partido'])

      }else if(this.puntosMarV>this.puntosMarL){
        this.documentoForm.value.GANADOR= this.visita[0].ID
        this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
this.router.navigate(['/admin/partido'])

      }

      this.puntosMarL=0
      this.puntosMarV=0
      this.setMar=1

      this.puntosV=0
      this.puntosL=0
      this.set=1


      localStorage.setItem('puntosV','0')
      localStorage.setItem('puntosL','0')
      localStorage.setItem('set','1')

      this.documentoForm.value.ID=localStorage.getItem('idPartido')
      this.documentoForm.value.LOCAL=this.local[0].ID
      this.documentoForm.value.VISITANTE=this.visita[0].ID
      this.documentoForm.value.PUNTOSL= this.puntosMarL
      this.documentoForm.value.PUNTOSV= this.puntosMarV
      this.documentoForm.value.SETP= this.setMar

      this.service.actualizarPartido(this.documentoForm.value).subscribe(res => {
        this.partidoAct = res.body;


      })






    }})

  }



  terminarSets(){






        if(this.mostrarSetL>this.mostrarSetV){
          this.documentoForm.value.GANADOR= this.local[0].ID
          this.alertas.alertaCelebracion( this.local[0].NOMBRE + ' ha ganado el partido!')
this.puntuacion()
          this.reiniciarMSet()

          this.terminarPA()
          this.router.navigate(['/admin/partido'])

        }else if(this.mostrarSetV>this.mostrarSetL){
          this.documentoForm.value.GANADOR= this.visita[0].ID
          this.alertas.alertaCelebracion(this.visita[0].NOMBRE + ' ha ganado el partido!')
           this.puntuacion()
           this.reiniciarMSet()

          this.terminarPA()
  this.router.navigate(['/admin/partido'])

        }

        this.puntosMarL=0
        this.puntosMarV=0
        this.setMar=1

        this.puntosV=0
        this.puntosL=0
        this.set=1


        localStorage.setItem('puntosV','0')
        localStorage.setItem('puntosL','0')
        localStorage.setItem('set','1')
        localStorage.setItem('Set','1')
        this.documentoForm.value.ID=localStorage.getItem('idPartido')
        this.documentoForm.value.LOCAL=this.local[0].ID
        this.documentoForm.value.VISITANTE=this.visita[0].ID
        this.documentoForm.value.PUNTOSL= this.puntosMarL
        this.documentoForm.value.PUNTOSV= this.puntosMarV
        this.documentoForm.value.SETP= this.setMar







      }



}
