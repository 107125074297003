import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SweetAlertService {
  timeLeft: number = 30;
  interval:any
  minutes: number = 0;
  start:any
  playAudio(){
    let audio = new Audio();
    audio.src = "/assets/audio/alarma.mp3";
    audio.load();
    audio.play();
  }

  constructor() { }



alertaCelebracion( mensaje:string){


  Swal.fire({

    title: mensaje,
    width: 600,
    padding: "3em",
    color: "#716add",
    // background: "#fff url(/images/trees.png)",
    backdrop: `
      rgba(0,0,123,0.4)
      url("https://i.imgur.com/tMnoN80.gif")
      top left
      no-repeat
    `

  });

}

alertaDescanso( mensaje:string, equipo:string){

  var soundfile = "/assets/audio/alerta.mp3";
  let timerInterval;
let conteo;
  Swal.fire({

timer:40000,
timerProgressBar: true,


    didOpen:()=>{
      Swal.showLoading();
      var audplay = new Audio(soundfile)
      audplay.play();
     conteo= `${Swal.getTimerLeft()}`
      timerInterval = setInterval(() => {

      }, 1000);

    },
    willClose: () => {
      clearInterval(this.interval);
      Swal.DismissReason.timer

    },

    title: mensaje+ ' por: '+ equipo,
    width: 600,
    padding: "3em",
    color: "#716add",
    // background: "#fff url(/images/trees.png)",
    // backdrop: `
    //   rgba(0,0,123,0.4)
    //   url("https://i.imgur.com/hMcxvnK.gif")
    //   top left
    //   no-repeat
    // `
  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
      this.playAudio();
    }
this.alertaRealizado()

  });;

}

alertaPartdio( mensaje:string){


  let timerInterval;
let conteo;
  Swal.fire({

timer:3000,
timerProgressBar: true,

    didOpen:()=>{
      Swal.showLoading();

     conteo= `${Swal.getTimerLeft()}`
      timerInterval = setInterval(() => {

      }, 1000);

    },
    willClose: () => {
      clearInterval(this.interval);
      Swal.DismissReason.timer

    },

    title: mensaje,
    width: 600,
    padding: "3em",
    color: "#716add",
    // background: "#fff url(/images/trees.png)",
    // backdrop: `
    //   rgba(0,0,123,0.4)
    //   url("https://i.imgur.com/hMcxvnK.gif")
    //   top left
    //   no-repeat
    // `

  }).then((result) => {
    /* Read more about handling dismissals below */
    if (result.dismiss === Swal.DismissReason.timer) {
      console.log("I was closed by the timer");
      this.playAudio();
    }
this.alertaRealizado()

  });;
this.start=true
}





alertaPunto(equipo:string){

  return Swal.fire({


    showConfirmButton: false,
    timer: 1500,
    backdrop: `
      rgba(0,0,123,0.4)
      url("https://i.imgur.com/ATOhwov.gif")
      top left
      no-repeat
    `

  }).then( res=> {
 this.reproducir()
  })






}

reproducir() {
  const audio = new Audio('/src/assets/img/alerta.mp3');
  audio.play();
}

alertaRealizado(){






  return Swal.fire({



    imageUrl: "https://i.imgur.com/bCucLdH.gif",
    imageWidth: 400,
    imageHeight: 453,

    position: 'top',

    title: 'Tiempo de Jugar!',
    showConfirmButton: false,
    timer: 2500,





  })
}


  alertaConfirmacionEliminar(titulo: string, contenido: string):Promise<any> {
    return Swal.fire({
      title: titulo,
      text: contenido,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar'
    })
  }





  alertaError(titulo: string, contenido: string):Promise<any> {
    return Swal.fire({
      icon: 'error',
      title: titulo,
      text: contenido
    })
  }

  alertaErrorVersion(titulo: string, contenido: string): Promise<any> {
    return Swal.fire({
      icon: 'error',
      title: titulo,
      text: contenido,
      backdrop: true,
      allowOutsideClick: false,
      confirmButtonColor: "#0a93e2",

    });
  }

  realizado(titulo: string, contenido: string):Promise<any> {
    return Swal.fire({
      icon: 'success',
      title: titulo,
      text: contenido
    })
  }
  alertaEstacionarse(title: string, contenido: any){
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      text: contenido,
      showConfirmButton: true,
      backdrop: false,
      allowOutsideClick: false
    });
  }
  alertaConfirmacion(titulo: string, contenido: string): Promise<any> {
    return Swal.fire({
      title: titulo,
      text: contenido,
      icon: 'success',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Aceptar',
      cancelButtonText: 'Cancelar',
      backdrop: false,
      allowOutsideClick: false
    });
  }



  alertaErrorPago(titulo: string, contenido: string, ): Promise<any> {
    return Swal.fire({
      icon: 'error',
      title: titulo,
      text: contenido,
      backdrop: false,
      allowOutsideClick: false,
      confirmButtonColor: "#0a93e2",
    });
  }

  alertaRealizadoAsistencia(title: any, contenido:any){
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: title,
      text:contenido,
      showConfirmButton: true,
      timer: 1500
    })
  }

  alertaAsistencia(title: any, contenido:any){
    return Swal.fire({
      position: 'center',
      icon: 'info',
      title: title,
      text:contenido,
      showConfirmButton: true,
      allowOutsideClick: false
    })
  }


  alertaConfirmacionEditar(titulo: string, contenido: string):Promise<any> {
    return Swal.fire({
      title: titulo,
      text: contenido,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Editar',
      cancelButtonText: 'Cancelar'
    })
  }

  alertaConfirmacionAgregar(titulo: string, contenido: string):Promise<any> {
    return Swal.fire({
      title: titulo,
      text: contenido,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Agregar',
      cancelButtonText: 'Cancelar'
    })
  }

  msjEnviado(){
    return Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Mensaje enviado',
      showConfirmButton: true,
      timer: 1500
    });
  }
}
